@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
body {
  margin: 0px; }

.addVoice {
  background: transparent;
  border: 2px solid white;
  border-radius: 1.5vh;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Work Sans", sans-serif;
  font-size: calc(20px + 1vh);
  margin-top: 5vh;
  padding: 2vh 4vh;
  text-decoration: none; }

.menuLogoIcon {
  height: calc(30px + 1.5vh);
  margin-right: 5vw; }

.mobileNav {
  background: transparent;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  width: 100vw; }

.nav {
  position: fixed;
  top: 0;
  z-index: 1000; }

.navbar {
  background-color: #2F343E;
  width: 100vw;
  max-width: 100%;
  height: 100px;
  padding-top: calc(10px + 1vh);
  padding-bottom: calc(10px + 1vh);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;
  position: relative; }

.navbarLogo {
  color: #FFFFFF;
  margin-left: 5vw;
  font-size: calc(30px + 1.5vh);
  text-decoration: none;
  float: left;
  font-weight: bold; }

.navLink {
  color: #FFFFFF;
  font-family: "Work Sans", sans-serif;
  font-size: calc(20px + 1vh);
  padding-bottom: 2vh;
  padding-top: 2vh;
  text-decoration: none; }

.navMenu {
  background-color: #2F343E;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px - 2*(10px + 1vh));
  align-items: center;
  padding: 5vw;
  position: absolute;
  top: calc(100px + 2*(10px + 1vh));
  width: 100vw;
  z-index: 2000; }

.addVoiceLink {
  text-decoration: none;
  color: #FFFFFF; }
