@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.footer {
  color: white;
  padding-top: calc(40px + 1vh);
  padding-bottom: calc(30px + 1vh);
  background-color: #2f343e;
  width: 100%;
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 0px 106px rgba(0, 0, 0, 0.8);
  z-index: 3; }

.container {
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-right: 10vw;
  margin-left: 10vw; }

.linkContainer {
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between; }

.category {
  width: 40vw;
  padding-bottom: 40px; }

.heading {
  text-transform: uppercase;
  line-height: 60px;
  font-size: calc(40px + 6 * ((10vw - 320px) / 680)); }

.navLink {
  font-size: calc(30px + 6 * ((10vw - 320px) / 680));
  color: #C0C0C0;
  text-decoration: none;
  line-height: 40px; }

.navLink:hover {
  text-decoration: underline; }

.addVoiceLink {
  text-decoration: none; }

.button {
  width: 40vw;
  text-align: center;
  background: transparent;
  color: white;
  border: 2px solid white;
  font-size: calc(35px + 6 * ((10vw - 320px) / 680));
  letter-spacing: 1px;
  padding: 10px 19px;
  margin-top: 10px;
  border-radius: 8px;
  text-transform: uppercase;
  cursor: pointer; }

.copyright {
  padding-top: calc(20px + 1vh);
  align-self: center;
  opacity: 0.5;
  font-size: calc(30px + 6 * ((10vw - 320px) / 680)); }
