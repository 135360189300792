@import url("https://fonts.googleapis.com/css2?family=WorkSans&display=swap");
body {
  margin: 0px; }

.navbar {
  background-color: #2F343E;
  width: 100vw;
  max-width: 100%;
  height: 70px;
  padding-top: calc(10px + 1vh);
  padding-bottom: calc(10px + 1vh);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Work Sans", sans-serif;
  position: relative;
  box-shadow: 0px 0px 106px rgba(0, 0, 0, 0.8);
  z-index: 6000;
  cursor: pointer; }

.navOptions {
  display: flex;
  float: right;
  padding-right: 5vw;
  padding-left: 5vw;
  text-decoration: none;
  align-items: center;
  justify-content: flex-end; }

.navLink {
  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 5px;
  color: #C0C0C0;
  text-decoration: none;
  text-transform: uppercase; }

.navLink:hover {
  color: #C0C0C0;
  text-decoration: underline; }

.navbarLogo {
  color: #FFFFFF;
  margin-left: 3vw;
  font-size: calc(15px + 1vw);
  text-decoration: none;
  float: left;
  font-weight: bold; }

.addVoiceLink {
  text-decoration: none;
  color: #FFFFFF; }

.addVoice {
  background: transparent;
  margin-left: 1vw;
  color: white;
  border: 2px solid white;
  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  letter-spacing: 1px;
  padding: 10px 19px;
  border-radius: 8px;
  text-transform: uppercase;
  cursor: pointer; }

.navbarMenuBtn {
  display: none; }

@media only screen and (max-width: 1000px) {
  .navLink {
    font-size: calc(16px + 1vw); }
  .addVoice {
    font-size: calc(16px + 1vw); }
  .navOptions {
    display: none; }
  .navbarLogo {
    font-size: calc(15px + 2vw);
    margin-left: 4vw; }
  .navbarMenuBtn {
    display: block;
    margin-right: 4vw;
    float: right; }
  .navbarMenuIcon {
    height: 40px; }
  .navbarMenu {
    background-color: #2F343E;
    width: 100vw;
    max-width: 100%;
    height: calc(100vh - (70px + 2 * (10px + 1vh)));
    position: absolute;
    top: calc(70px + 2 * (10px + 1vh));
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Work Sans", sans-serif;
    text-decoration: none;
    transition: height 0.5s;
    transition-timing-function: ease-out; }
  .navLink {
    padding-top: 50px; }
  .addVoice {
    margin-top: 100px; } }
