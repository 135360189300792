@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.footer {
  color: white;
  padding-top: calc(40px + 1vh);
  padding-bottom: calc(30px + 1vh);
  background-color: #2f343e;
  width: 100%;
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 0px 106px rgba(0, 0, 0, 0.8);
  z-index: 3; }

.container {
  width: 90vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 5vw;
  margin-left: 5vw; }

.linkContainer {
  width: 60vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between; }

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  width: 200px;
  margin-bottom: 20px; }

.heading {
  text-transform: uppercase;
  line-height: 40px; }

.navLink {
  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  color: #C0C0C0;
  text-decoration: none;
  line-height: 30px; }

.navLink:hover {
  text-decoration: underline; }

.addVoiceLink {
  text-decoration: none; }

.button {
  text-align: center;
  background: transparent;
  color: white;
  border: 2px solid white;
  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  letter-spacing: 1px;
  padding: 10px 19px;
  border-radius: 8px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 15px; }

.copyright {
  padding-top: calc(20px + 1vh);
  align-self: center;
  opacity: 0.5;
  font-size: calc(14px + 6 * ((10vw - 320px) / 680)); }

.category {
  padding: 1vh; }

@media only screen and (max-width: 650px) {
  .addVoice {
    margin-left: auto; } }
