.divWrapper {
  height: calc(93vh - 70px);
  width: 100vw; }

.parallax {
  position: absolute;
  background: linear-gradient(#1c2029, 95%, rgba(28, 32, 41, 0));
  left: 50%;
  top: 0%;
  border-radius: 0%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 200vh; }

.mission {
  padding: 15%;
  position: absolute;
  top: 33%;
  left: 0%;
  transform: translate(0%, -50%);
  color: #FFFFFF;
  font-family: 'Work Sans', sans-serif;
  font-size: calc(15px + 1vw);
  text-align: center;
  text-decoration: none;
  float: center;
  font-weight: bold;
  letter-spacing: 0.15rem; }

.descrip {
  padding: 15%;
  position: absolute;
  top: 60%;
  left: 0%;
  transform: translate(0%, -50%);
  color: #FFFFFF;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: calc(10px + 1vw);
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em; }

.exploreWrapper {
  cursor: pointer; }

.explore {
  padding: 0%;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #BDE3FE;
  font-family: 'Work Sans', sans-serif;
  font-size: calc(12px + 1vw);
  text-align: center;
  text-decoration: none;
  float: center;
  font-weight: bold;
  letter-spacing: 0.1rem; }

.arrow {
  padding: 0%;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%); }

.arrowimage {
  width: 100px;
  height: 60px;
  cursor: pointer; }
