.container {
  width: 100%;
  padding-bottom: 10vw;
  position: relative; }

.img {
  border-radius: 5%;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden; }

.middle {
  box-sizing: border-box;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 5vw;
  padding-bottom: 10vw;
  position: absolute;
  top: 0;
  left: 0px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  text-align: center; }

.container:hover .img {
  opacity: 0.2; }

.container:hover .middle {
  opacity: 1; }

.seevoicebtn {
  background-color: #596171;
  border-radius: 5vw;
  color: #FFFFFF;
  font-family: "Work Sans", sans-serif;
  font-size: calc(27px + 1vh);
  font-weight: 500;
  padding: 2vw 5vw; }

.text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  background-color: transparent;
  color: white;
  padding: 10px 10px;
  font-family: "Work Sans", sans-serif;
  font-size: calc(27px + 1vh); }
