.container {
  width: 85%;
  position: relative;
  padding-top: 10vw;
  padding-bottom: 10vw; }

.img {
  border-radius: 5%;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden; }

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  text-align: center; }

.container:hover .img {
  opacity: 0.2;
  cursor: pointer; }

.container:hover .middle {
  opacity: 1;
  cursor: pointer; }

.text {
  background-color: transparent;
  color: white;
  font-size: 17px;
  padding: 10px 10px;
  font-family: 'Montserrat';
  cursor: pointer; }
