.html {
  scroll-behavior: smooth; }

.body {
  margin: 0;
  background: #1C2029;
  height: 100%; }

.header {
  box-shadow: 0px 0px 106px rgba(0, 0, 0, 0.8);
  height: calc(100px + 2*(10px + 1vh));
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%; }

.content {
  margin-top: calc(100px + 2*(10px + 1vh)); }
