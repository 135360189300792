@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap");
.contentHeading {
  flex-grow: 3;
  font-family: "Work Sans", sans-serif;
  font-size: calc(30px + 2vh);
  font-weight: 500;
  letter-spacing: 0.02em; }

.contentExplore {
  color: #BDE3FE;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: "Work Sans", sans-serif;
  font-size: calc(24px + 1vh);
  font-weight: 600;
  justify-content: space-between;
  text-align: center; }

.contentSubheading {
  flex-grow: 5;
  font-family: "Work Sans", sans-serif;
  font-size: calc(27px + 1vh);
  font-weight: 300; }

.downIcon {
  width: calc(20px + 5vh); }

.landing {
  background-size: cover;
  height: calc(100vh - 10vh - 2*(10px + 1vh));
  min-height: 700px;
  overflow: hidden;
  position: relative;
  width: 100vw; }

.landingBG {
  object-fit: cover;
  position: absolute; }

.landingContent {
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 10vh 10vw;
  position: absolute; }

.landingGrad {
  background-image: linear-gradient(#1c2029, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.5), #1b1f28);
  height: 100%;
  position: absolute;
  width: 100vw; }
